import { FunctionComponent, SVGProps, useEffect, useState } from "react";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { ReactComponent as UserIcon } from "assets/user-circle.svg";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";

export const useLanguageSwitch = () => {
    const [isReady, setIsReady] = useState(false);
    const [locale, setLocale] = useState<string|undefined>(undefined);
    const { i18n } = useModifiedTranslation();
    const { data: contactData } = useGetContactInfo();
    
    useEffect(() => {
        const languages = getAvailableLanguages();

        const language = window.localStorage.getItem('language');

        if (language && languages.includes(language)) {
            setLocale(language);
            return;
        }

        if (language){
            removeLanguageFromStorage().then(() => {
                saveLanguageInStorage(languages[0])
                setLocale(languages[0]);
            });
        } else {
            saveLanguageInStorage(languages[0])
            setLocale(languages[0]);
        }

    }, []);

    useEffect(() => {
        if (locale) {
            i18n.changeLanguage(locale, () => {
                setIsReady(true);
            });
        }

        return () => {
            setIsReady(false);
        };
    }, [i18n, locale]);

    const setLanguage = (language: string) => {
        saveLanguageInStorage(language).then(() => {
            setLocale(language);
            window.location.reload();
        });
    }

    const getAvailableLanguages = (): string[] => {
        const hostname = window.location.hostname;
        const domain = hostname.split('.').pop()?.toUpperCase() ?? null;
        const languages = (process.env['REACT_APP_LANGUAGES_' + domain] ?? 'en-EN').split(',');
        
        return languages;
    }

    const saveLanguageInStorage = async (language:string) => {
        return new Promise((resolve) => {
            window.localStorage.setItem('language', language);
            resolve(true);
        });
    }

    const removeLanguageFromStorage = async () => {
        return new Promise((resolve) => {
            window.localStorage.removeItem('language');
            resolve(true);
        });
    }

    const languageSwitchMenus = (): Array<string | { label: string; action: () => void; Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined; }>; selected: boolean; id: string; }> => 
    {
        let availableLanguages = getAvailableLanguages();

        const isLTUser = contactData?.tags?.includes('Company-LT');

        if (availableLanguages.length && isLTUser) {
            availableLanguages = availableLanguages.filter(language => language !== 'lv-LV');
        } else {
            availableLanguages = availableLanguages.filter(language => language !== 'lt-LT');
        }

        
        const storageLanguage = window.localStorage.getItem('language');

        if (!availableLanguages.includes(storageLanguage ?? '')) {
            removeLanguageFromStorage().then(() => {
                let language = availableLanguages[0];

                if (isLTUser && availableLanguages.includes('lt-LT')) {
                    language = 'lt-LT'; 
                } else if (!isLTUser && availableLanguages.includes('lv-LV')) {
                    language = 'lv-LV';
                }
                
                saveLanguageInStorage(language);
                setLocale(language);
            });
        }

        const menu: (string | { label: string; action: () => void; Icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined; }>; selected: boolean; id: string; })[] = [];

        if (availableLanguages && availableLanguages.length > 1) {
            availableLanguages.forEach((lang) => {
                const splitName = lang.split('-');
                menu.push({
                    label: splitName[0].toUpperCase(),
                    action: () => {
                        setLanguage(lang);
                    },
                    Icon: UserIcon,
                    selected: locale === lang,
                    id: `userMenu-language-${lang}`,
                });
            });
        } 

        return menu;
    }

    return { isReady, setLanguage, languageSwitchMenus };
};




/* eslint-disable react-hooks/rules-of-hooks */
import { MutableRefObject, useState, useEffect, useMemo } from "react";
import { ExecutionMethod } from "api/enums";
import { SecurityTypeCode } from "api/holdings/types";
import { useGetSecurityDetails } from "api/holdings/useGetSecurityDetails";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetBuyData } from "api/trading/useGetBuyData";
import { useTrade } from "api/trading/useTrade";
import { ReactComponent as InfoIcon } from "assets/information-circle.svg";
import {
  Checkbox,
  PortfolioSelect,
  DownloadableDocument,
  Input,
  LabeledDiv,
  Select,
  LoadingIndicator,
  KIDTooltip,
} from "components/index";
import { LabeledDivFlex } from "components/LabeledDiv/LabeledDivFlex";
import TradingActionButton from "components/TradingActionButton/TradingActionButton";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { useKeycloak } from "providers/KeycloakProvider";
import { Tooltip } from "react-tooltip";
import { cblPortalGroupTag } from "services/permissions/usePermission";
import { getBackendTranslation } from "utils/backTranslations";
import { handleNumberInputEvent, handleNumberPasteEvent, isValidInputByStep } from "utils/input";
import { round, roundDown } from "utils/number";
import {
  getBalanceAmountByCurrency,
  getBlockSizeErrorTooltip,
  getBlockSizeMinTradeAmount,
  getTradeAmountTooltip,
} from "utils/trading";
import { addProtocolToUrl } from "utils/url";
import { useGetAvailableBalancesExperimental } from "../../../api/overview/useGetAvailableBalancesExperimental";
import {useGetFinancialProfile} from "../../../api/trading/useGetFinancialProfile";
import { filterJustBrokerage } from "../../../services/permissions/usePermission";
import { useOrderTypeFields, EOrderType } from "../useOrderTypeFields";
import { useProfileTestResults } from "../useProfileTestResults";
import { useTradablePortfolioSelect } from "../useTradablePortfolioSelect";
import DisclaimerBlock from "./DisclaimerBlock";
import { useGetBuyTradeType } from "./useGetBuyTradeType";

export interface BuyModalInitialData {
  id: number;
  securityCode: string | null;
}

interface BuyModalProps extends BuyModalInitialData {
  modalInitialFocusRef: MutableRefObject<null>;
  onClose: () => void;
}

export const isSecurityTypeFund = (
  securityType: SecurityTypeCode | undefined
) => securityType === SecurityTypeCode.COLLECTIVE_INVESTMENT_VEHICLE;

const getTradeType = (securityType: SecurityTypeCode | undefined) =>
  isSecurityTypeFund(securityType) ? "subscription" : "buy";

const FALLBACK_DECIMAL_COUNT = 2;

export const BuyModalContent = ({
  modalInitialFocusRef,
  onClose,
  id: securityId,
  securityCode,
}: BuyModalProps) => {
  const [submitting, setSubmitting] = useState(false);
  const { t, i18n } = useModifiedTranslation();
  const { selectedContactId } = useGetContractIdData();
  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(
    false,
    selectedContactId
  );
  const { setPortfolioId, portfolioOptions, portfolioId } =
    useTradablePortfolioSelect();

  const filteredPortfolioOptions = filterJustBrokerage(portfolioOptions);
  const selectedPortfolioId = portfolioId
      || (filteredPortfolioOptions.length === 1 ? filteredPortfolioOptions[0]?.id : undefined);
  const selectedPortfolio = useMemo(() => {
    return portfolios.find((p) => p.id === selectedPortfolioId);
  }, [portfolios, selectedPortfolioId]);


  const { data: security, loading: loadingSecurity } = useGetSecurityDetails(
      securityCode?.toString() ?? securityId?.toString(),
      selectedPortfolio?.currency?.securityCode,
      !!securityCode
  );

  const { loading: loadingCash, data: portfolioData } = useGetBuyData(
    selectedPortfolioId,
    security?.currency?.securityCode
  );

  const securityTypeCode = security?.type?.code;

  const defaultAccount =
    portfolioData?.accounts?.find(
      (a) => a?.currency?.securityCode === portfolioData?.currency?.securityCode
    ) || portfolioData?.accounts?.[0];

  const securityToAccountFxRate = 1 / (defaultAccount?.currency?.fxRate || 1);

  const { isTradeInUnits, canToggleTradeType, setIsTradeInUnits } =
    useGetBuyTradeType(security?.tagsAsSet, securityTypeCode);

  const [input, setInput] = useState<string>("");
  const inputAsNr = input ? Number(input) : 0;


  const securityAmountDecimalCount =
    security?.amountDecimalCount !== undefined
      ? security.amountDecimalCount
      : FALLBACK_DECIMAL_COUNT;
  const securityCurrencyAmountDecimalCount =
    security?.currency?.amountDecimalCount !== undefined
      ? security?.currency?.amountDecimalCount
      : FALLBACK_DECIMAL_COUNT;
  const portfolioCurrencyAmountDecimalCount =
    portfolioData?.currency?.amountDecimalCount !== undefined
      ? portfolioData?.currency?.amountDecimalCount
      : FALLBACK_DECIMAL_COUNT;
  const accountCurrencyAmountDecimalCount =
    defaultAccount?.currency?.amountDecimalCount !== undefined
      ? defaultAccount?.currency?.amountDecimalCount
      : FALLBACK_DECIMAL_COUNT;
  const inputBlockSize = isTradeInUnits
    ? securityAmountDecimalCount
    : portfolioCurrencyAmountDecimalCount;

  const securityName =
    security !== undefined
      ? getBackendTranslation(
          security?.name ?? '',
          security?.namesAsMap,
          i18n.language
        )
      : undefined;

  const securityToPortfolioFxRate = 1 //security?.fxRate || 1;
  const securityPrice = security?.latestMarketData?.price;

  const securityPriceInPfCurrency =
    securityPrice !== undefined
      ? round(
          securityPrice * securityToPortfolioFxRate,
          portfolioCurrencyAmountDecimalCount
        )
      : undefined;

  const securityPriceInAccCurrency =
    securityPrice !== undefined
      ? round(
          securityPrice * securityToAccountFxRate,
          portfolioCurrencyAmountDecimalCount
        )
      : undefined;

  const unitsToBuyFromTradeAmount =
    securityPriceInPfCurrency !== undefined
      ? inputAsNr / (securityPriceInPfCurrency || 1)
      : undefined;

  const unitsToBuy = isTradeInUnits
    ? roundDown(inputAsNr, securityAmountDecimalCount)
    : unitsToBuyFromTradeAmount !== undefined
    ? roundDown(unitsToBuyFromTradeAmount, securityAmountDecimalCount)
    : undefined;

    const { orderType, setOrderType, orderTypeOptions,
      orderExpiration, setOrderExpiration, orderExpirationOptions
      , unitPrice, setUnitPrice, getFormattedOrderTypeFields,
        isOrderTypeFieldsVisible, setConfirmationIsChecked, isConfirmationChecked,
        getDisclaimerKeys } = useOrderTypeFields();

    const [stringUnitPrice, setStringUnitPrice] = useState<string>("");

    useEffect(() => {
      setUnitPrice(+stringUnitPrice);
    }, [setUnitPrice, stringUnitPrice]);

  let estimatedTradeAmountInPfCurrency = 
    unitsToBuy !== undefined && securityPriceInPfCurrency !== undefined
      ? round(
          unitsToBuy * securityPriceInPfCurrency,
          portfolioCurrencyAmountDecimalCount
        )
      : undefined;

  //------estimateTradeAmount variations -----
    if (estimatedTradeAmountInPfCurrency && security?.type?.code === SecurityTypeCode.COLLECTIVE_INVESTMENT_VEHICLE) {
      estimatedTradeAmountInPfCurrency = inputAsNr;
    }
  
    if (securityPrice && estimatedTradeAmountInPfCurrency && security?.type?.code === SecurityTypeCode.DEBT_INSTRUMENT) {
      if (orderType?.id === EOrderType.LIMIT_ORDER) {
        estimatedTradeAmountInPfCurrency = round(
          (+inputAsNr * +(unitPrice || 0)) / 100,
          portfolioCurrencyAmountDecimalCount
        );
      } else {
        estimatedTradeAmountInPfCurrency = round(
          (+inputAsNr * +securityPrice) / 100,
          portfolioCurrencyAmountDecimalCount
        );
      }
    }
  
    if (securityTypeCode && securityPrice && estimatedTradeAmountInPfCurrency 
      && orderType?.id === EOrderType.LIMIT_ORDER
      && [SecurityTypeCode.EQUITY, SecurityTypeCode.ETF].includes(securityTypeCode)) {
        estimatedTradeAmountInPfCurrency = round(
          (+inputAsNr * +(unitPrice || 0)),
          portfolioCurrencyAmountDecimalCount
        );
    }
  //-----------------
      
  const estimatedTradeAmountInAccountCurrency =
    unitsToBuy !== undefined && securityPriceInAccCurrency !== undefined
      ? round(
          unitsToBuy * securityPriceInAccCurrency,
          accountCurrencyAmountDecimalCount
        )
      : undefined;

  const estimatedTradeAmountInSecurityCurrency =
    unitsToBuy !== undefined && securityPrice !== undefined
      ? round(unitsToBuy * securityPrice, securityCurrencyAmountDecimalCount)
      : undefined;

  const calculatedReportFxRate =
    (estimatedTradeAmountInSecurityCurrency || 0) /
    (estimatedTradeAmountInPfCurrency || 1);

  const calculatedAccountFxRate =
    (estimatedTradeAmountInSecurityCurrency || 0) /
    (estimatedTradeAmountInAccountCurrency || 1);

  const { handleTrade: handleBuy } = useTrade({
    tradeType: getTradeType(securityTypeCode),
    portfolio: selectedPortfolio || portfolios[0],
    securityName: securityName || "-",
    units: isTradeInUnits ? unitsToBuy : undefined,
    tradeAmount: !isTradeInUnits
      ? estimatedTradeAmountInSecurityCurrency
      : undefined,
    securityCode: security?.securityCode || "",
    executionMethod: 
    security?.type?.code === SecurityTypeCode.COLLECTIVE_INVESTMENT_VEHICLE ? ExecutionMethod.GROSS_TRADE_AMOUNT :
      isTradeInUnits
        ? ExecutionMethod.UNITS
        : ExecutionMethod.NET_TRADE_AMOUNT,
    accountFxRate: calculatedAccountFxRate,
    reportFxRate: calculatedReportFxRate,
    intInfo: isConfirmationChecked ? 'Appropriateness test warning accepted' : '',
    ...getFormattedOrderTypeFields(securityTypeCode)
  });

  const accountName = selectedPortfolio?.accounts[0].accountName.split('-')[0];
  const externalId = selectedPortfolio?.shortName.split('-')[0];

  const {data: financialProfile} = useGetFinancialProfile(externalId);
  const financialContact = financialProfile?.contacts[0] ?? null;

  const { balances } = useGetAvailableBalancesExperimental(externalId);

  const tradingBalances = balances[accountName || '']?.trading || [];
  const securityCurrency = security?.currency?.securityCode;

  const availableCash = getBalanceAmountByCurrency(tradingBalances, securityCurrency);
  const portfolioCurrency = securityCurrency;

  const insufficientCash =
    (availableCash || 0) < (estimatedTradeAmountInPfCurrency || 0); // less than trying to buy for

  const { readonly } = useKeycloak();

  const tradeAmountTooltip = () => {

    if (security?.type?.code === SecurityTypeCode.COLLECTIVE_INVESTMENT_VEHICLE ) {
      return t("tradingModal.approximateTradeAmountTooltip");
    }

    if (security?.type?.code === SecurityTypeCode.EQUITY 
      || security?.type?.code === SecurityTypeCode.ETF) {
        if (unitsToBuy !== undefined &&
            security !== undefined &&
            security &&
            portfolioCurrency !== undefined) {
              return getTradeAmountTooltip(
                unitsToBuy,
                security,
                security.fxRate,
                portfolioCurrency,
                i18n.language,
                t,
              )
            } 
    }

    return undefined
  }

  //min trade amount allowed to trade in this security
  //based on its block size only
  const blockSizeMinTradeAmountInPfCurrency =
    securityPrice !== undefined
      ? round(
          getBlockSizeMinTradeAmount(
            securityAmountDecimalCount,
            securityPrice
          ) * securityToPortfolioFxRate,
          portfolioCurrencyAmountDecimalCount
        )
      : undefined;

  const blockSizeTradeAmountError =
    !isTradeInUnits &&
    inputAsNr > 0 &&
    security !== undefined &&
    security &&
    blockSizeMinTradeAmountInPfCurrency !== undefined &&
    portfolioCurrency !== undefined &&
    estimatedTradeAmountInPfCurrency !== undefined &&
    blockSizeMinTradeAmountInPfCurrency > estimatedTradeAmountInPfCurrency //input is lower than min allowed trade amount
      ? getBlockSizeErrorTooltip(
          blockSizeMinTradeAmountInPfCurrency,
          security,
          security.fxRate,
          portfolioCurrency,
          i18n.language,
          t,
          true
        )
      : undefined;

  useEffect(() => {
    //when switching between amount and trade amount
    //we must make sure the input is rounded to the allowed
    //amount of decimals
    setInput((currInput) =>
      currInput ? round(parseFloat(currInput), inputBlockSize).toString() : ""
    );
  }, [inputBlockSize]);

  const loading = loadingCash || loadingSecurity;

  const securityType: SecurityTypeCode | undefined = securityTypeCode;
  const isCBL: boolean | undefined = security?.profile?.attribute?.stringValue === "913";
  const securityClassType: string = security?.classType1?.value || '';
  const { keyPrefix, translationDisclaimerKeys } = getDisclaimerKeys(securityClassType, securityType, isCBL);

  const requiresConfirmationCheck = useProfileTestResults(selectedPortfolio?.shortName, securityType, security?.classType1?.value);

  const minimalNominalValue = securityTypeCode === SecurityTypeCode.DEBT_INSTRUMENT 
    && security?.minTradeAmount ? security.minTradeAmount : undefined;

  const stepNominalValue = securityTypeCode === SecurityTypeCode.DEBT_INSTRUMENT 
    && security?.blockSize ? security.blockSize : undefined;

  const disableBuyButton = () => {
    return (
      loading ||
      inputAsNr === 0 ||
      insufficientCash ||
      !!blockSizeTradeAmountError ||
      readonly ||
      !selectedPortfolio ||
      (requiresConfirmationCheck && !isConfirmationChecked) ||
      submitting ||
      Number.isInteger(inputAsNr) === false ||
      !!(minimalNominalValue && inputAsNr < minimalNominalValue) ||
      !!(stepNominalValue && minimalNominalValue && inputAsNr >= minimalNominalValue && (inputAsNr % stepNominalValue) !== 0)
    );
  };

  // comment for now, not sure if we need it
  
  // const amountInputLabel = () => {
  //     switch (securityTypeCode) {
  //         case SecurityTypeCode.EQUITY:
  //         case SecurityTypeCode.ETF:
  //             return t("tradingModal.unitsInputLabel");
  //         case SecurityTypeCode.DEBT_INSTRUMENT:
  //             return minimalNominalValue 
  //               ? `${t("tradingModal.valueInputLabel")} (${t('tradingList.minimalNominalValue')}:${minimalNominalValue})` 
  //               : t("tradingModal.valueInputLabel");
  //         default:
  //             return t("tradingModal.tradeAmountSimpleInputLabel");
  //     }
  // };
 
  const amountInputLabel = () => {
    switch (securityTypeCode) {
        case SecurityTypeCode.EQUITY:
            return t("transactionsPage.numberOfShares");
        case SecurityTypeCode.COLLECTIVE_INVESTMENT_VEHICLE:
          return t("transactionsPage.tradeAmount");
        case SecurityTypeCode.DEBT_INSTRUMENT:
            return minimalNominalValue 
              ? `${t("transactionsPage.nominalValue")} (${t('tradingList.minimalNominalValue')}:${minimalNominalValue})` 
              : t("transactionsPage.nominalValue");
        default:
            return t("transactionsPage.quantity");
    }
};

  const uniqueProfileCurrencies = selectedPortfolio?.accounts
      .map((account) => account.accountName.split('-').pop())
      .filter((value, index, self) => self.indexOf(value) === index);

  const actionButton = <TradingActionButton 
    size="md"
    disabled={disableBuyButton()}
    isLoading={submitting}
    buyLabel={t("tradingModal.buyButtonLabel")}
    testLabel={t("tradingModal.fillTheTest")}
    userClassification={financialContact?.classification?.code}
    securityType={security?.type?.code}
    securityClassType1={security?.classType1?.value}
    currencySecurityCode={security?.currency.securityCode }
    onClick={async () => {
      setSubmitting(true);
      const response = await handleBuy();
      if (response) {
          onClose();
      }
    }}
    financialContactProfile={financialContact?.profile ?? null}
    uniqueCurrencies={uniqueProfileCurrencies as []}
  />

  const hasMarketData = !!security?.latestMarketData;

  const priceAsInteger = securityTypeCode && [
    SecurityTypeCode.EQUITY, 
    SecurityTypeCode.ETF,
  ].includes(securityTypeCode);


  const handleInputErrors = (): string => {
    if (selectedPortfolioId === undefined) {
      return "";
    }

    if (input && inputAsNr > 0) {
      if (priceAsInteger && Number.isInteger(inputAsNr) === false) {
        return t("tradingModal.tradeAmountIntegerError");
      }

      if (insufficientCash && !loading) {
        return t("tradingModal.insufficientCashError");
      }

      if (minimalNominalValue && inputAsNr < minimalNominalValue) {
        return t("tradingModal.minimalNominalValueError");
      }

      if (stepNominalValue && minimalNominalValue && inputAsNr >= minimalNominalValue && !isValidInputByStep(inputAsNr, stepNominalValue) ) {
        return t("tradingModal.stepNominalValueError", {
          step: stepNominalValue,
        });
      }

    }

    return "";
  };

  const additionalCostsLabel = securityTypeCode && securityTypeCode === SecurityTypeCode.DEBT_INSTRUMENT ? t("tradingModal.additionalCostsDEBT") 
    : securityTypeCode && securityTypeCode === SecurityTypeCode.COLLECTIVE_INVESTMENT_VEHICLE 
      && security?.tagsAsSet.includes(cblPortalGroupTag)
        ? '' 
        : t("tradingModal.additionalCosts");


  return (
      <div className="grid gap-2 min-w-[min(84vw,_375px)]">
        {securityName && (
            <LabeledDiv
                label={t("tradingModal.securityName")}
                className="max-w-24ch text-2xl font-semibold"
            >
              {securityName}
            </LabeledDiv>
        )}
        {loadingSecurity && <LoadingIndicator size="sm"/>}
        {security?.url2 && (
            <div className="flex items-center w-fit">
              <DownloadableDocument
                  url={addProtocolToUrl(security?.url2)}
                  label={t("tradingModal.kiid")}
              />
              <KIDTooltip />
            </div>
        )}

        {(!hasMarketData) && <div>{t("tradingModal.noMarketData")}</div>}

        {hasMarketData &&<PortfolioSelect
            portfolioOptions={filteredPortfolioOptions}
            portfolioId={selectedPortfolioId}
            onChange={(newPortfolio) => setPortfolioId(newPortfolio.id)}
            label={t("tradingModal.portfolio")}
            error={!selectedPortfolioId ? t("tradingModal.selectPortfolioError") : ""}
        />}
        {hasMarketData && !loadingCash && (
            <LabeledDiv
                label={t("tradingModal.availableCash")}
                className="text-xl font-semibold text-gray-700"
            >
              {availableCash !== undefined && portfolioCurrency !== undefined
                  ? t("numberWithCurrency", {
                    value: availableCash,
                    currency: portfolioCurrency,
                  })
                  : "-"}
            </LabeledDiv>
        )}
        {loadingCash && <LoadingIndicator size="xs"/>}
        {hasMarketData && <Input
            maxLength={15}
            disabled={!selectedPortfolioId}
            ref={modalInitialFocusRef}
            value={input}
            onChange={(event) => {
              handleNumberInputEvent(event, setInput, 0, undefined, inputBlockSize);
            }}
            onPaste={(event) => {
              handleNumberPasteEvent(event, setInput, 0, undefined, inputBlockSize);
            }}
            onKeyDown={(event) => {
              if (priceAsInteger && (event.key === '.' || event.key === ',')) {
                event.preventDefault();
              }
            }}
            label={amountInputLabel()}
            type="text"
            error={handleInputErrors()}
        />}

        {hasMarketData && isOrderTypeFieldsVisible(securityTypeCode) && (
            <>
              <div className="grid grid-flow-col gap-2">
                <LabeledDiv
                    label={t("tradingModal.orderType")}
                    className="text-xl font-semibold text-gray-700"
                >
                  <Select
                      value={orderType}
                      options={orderTypeOptions}
                      onChange={setOrderType}
                  />
                </LabeledDiv>

                <LabeledDiv
                    label={t("tradingModal.orderExpiration")}
                    className="text-xl font-semibold text-gray-700"
                >
                  <Select
                      value={orderExpiration}
                      options={orderExpirationOptions}
                      onChange={setOrderExpiration}
                  />
                </LabeledDiv>
              </div>

              {orderType?.id === EOrderType.LIMIT_ORDER &&
                  <LabeledDiv
                  label={securityTypeCode && [SecurityTypeCode.EQUITY, SecurityTypeCode.ETF].includes(securityTypeCode) 
                    ? t("tradingModal.limitPrice") : t("tradingModal.percentageOfNominalValue")}
                  className="text-xl font-semibold text-gray-700"
                >
                <Input
                    type="text"
                    value={stringUnitPrice}
                    onChange={(event) => {
                      handleNumberInputEvent(event, setStringUnitPrice, 0, undefined, inputBlockSize);
                    }}
                    onPaste={(event) => {
                      handleNumberPasteEvent(event, setStringUnitPrice, 0, undefined, inputBlockSize);
                    }}
                    onKeyDown={(event) => {
                      if (priceAsInteger && (event.key === '.' || event.key === ',')) {
                        event.preventDefault();
                      }
                    }}
                    label={""}
                    maxLength={15}
                />
              </LabeledDiv>
              }
            </>
        )}

          {hasMarketData && canToggleTradeType && (
              <>
                  <div
                      className="flex overflow-hidden font-medium leading-5 bg-gray-50 rounded-md divide-x ring-1 shadow-sm pointer-events-auto select-none divide-slate-400/20 text-[0.8125rem] ring-slate-700/10">
                <button
                    className={`text-center cursor-pointer py-2 px-4 flex-1 ${
                        isTradeInUnits ? "bg-gray-200" : ""
                    }`}
                    onClick={() => setIsTradeInUnits(true)}
                >
                  {t("tradingModal.unitsButtonLabel")}
                </button>

                <button
                    className={`text-center cursor-pointer py-2 px-4 flex-1 ${
                        !isTradeInUnits ? "bg-gray-200" : ""
                    }`}
                    onClick={() => setIsTradeInUnits(false)}
                >
                  {t("tradingModal.tradeAmountButtonLabel")}
                </button>
              </div>
            </>
        )}

        {hasMarketData && <hr className="my-2"/>}
        {hasMarketData && <div className="flex flex-col gap-4 items-stretch ">
              <div>
                  <LabeledDivFlex
                      alignText="center"
                      tooltipContent={tradeAmountTooltip()}
                      id="buyOrderModal-tradeAmount"
                      label={t("tradingModal.approximateTradeAmount")}
                      className="text-2xl font-semibold"
                  >
                      {portfolioCurrency !== undefined &&
                      estimatedTradeAmountInPfCurrency !== undefined
                          ? `${t("number", {
                              value: estimatedTradeAmountInPfCurrency,
                          })} ${portfolioCurrency} `
                          : "-"}
                  </LabeledDivFlex>
                
                  {securityTypeCode && orderType?.id !== EOrderType.LIMIT_ORDER 
                    && [SecurityTypeCode.EQUITY, SecurityTypeCode.ETF].includes(securityTypeCode) &&
                  <div className="mb-1">
                    <div className="flex flex-col items-center">
                      <div className="flex flex-row gap-x-1">
                        <div className="flex items-center text-sm font-normal">
                          <span>
                            {t("tradingModal.reservationFee")}
                            :
                          </span>
                          <span className="font-semibold">&nbsp;&nbsp;
                          {t("number", {
                                value: estimatedTradeAmountInPfCurrency ? 
                                (estimatedTradeAmountInPfCurrency * 0.10).toFixed(2)
                                : 0,
                            })} {portfolioCurrency}
                            &nbsp;&nbsp;
                          </span>
                          <InfoIcon data-tooltip-id="tooltip-reservation-fee" data-tooltip-html={t("tradingModal.reservationFeeTooltip")} />
                          <Tooltip id="tooltip-reservation-fee" variant="dark"/>
                        </div>
                      </div>
                    </div>
                  </div>}

                  {additionalCostsLabel && <div className="text-xs text-center text-gray-600">
                    {additionalCostsLabel}
                  </div>}
              </div>

              {hasMarketData && requiresConfirmationCheck && (
                  <div className="max-w-[min(84vw,_375px)]">
                      <hr className="my-2"/>
                      <div className="mb-3 font-normal leading-none">
                          <span className="text-xs font-semibold">{ t("tradingModal.confirmationWarningTitle") }</span>
                          <span className="text-xs">{ t("tradingModal.confirmationWarningText") }</span>
                      </div>
                      <Checkbox
                          label={securityType === SecurityTypeCode.DEBT_INSTRUMENT 
                            ? t("tradingModal.confirmationLabelDEBT")
                            : securityType === SecurityTypeCode.ETF
                            ? t("tradingModal.confirmationLabelETF") : ""}
                          checked={isConfirmationChecked}
                          onChange={setConfirmationIsChecked}
                          id="terms-checkbox"
                      />
                  </div>
              )}

              {actionButton}
          </div>}
          {hasMarketData && <DisclaimerBlock keyPrefix={keyPrefix} translationDisclaimerKeys={translationDisclaimerKeys} />}
      </div>
  );
};

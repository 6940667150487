import { ReactNode } from "react";
import classNames from "classnames";

interface GrayBadgeProps {
  children: ReactNode;
}

export const GrayBadge = ({ children }: GrayBadgeProps) => (
  <div
    className={classNames(
      "py-0.5 px-1.5 bg-gray-100 rounded-md text-xs font-medium text-center border",
      "bg-custom-gray border-gray-200 text-gray-900",
    )}
  >
    {children}
  </div>
);
